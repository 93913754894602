<template>
  <default>
     <v-chip-group mandatory active-class=" primary">
            <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard
            </v-chip> <span class="mt-2 mx-2"> > </span>
           <v-chip active-class="false"  :to="{ name: 'Products' }">Products
            </v-chip> <span class="mt-2 mx-2"> > </span>
           <v-chip :to="{ name: 'AkibaPlan' }">
            {{ $options.name }}
            </v-chip>
        </v-chip-group>
    <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-2 md:tw-mt-10">
      <card-title icon="/images/icons/top-icon.png">Elimu Plus Plan Calculator</card-title>
      <div class="tw-w-full tw-flex tw-flex-wrap tw-py-3 md:tw-py-6">
        <keep-alive>
          <component
          :is="current"
          :wealthbuilder="wealthbuilder"
          :benefits="benefits"
          :productCode = "productData.prodCode"
          :optionCode = "productData.optionCode"
          :terms="terms"
          :quoteSummaryData="quoteSummaryData"
          :frequency="Wealthfrequency"
          @fetchBenefits="fetchBenefits"
        ></component>
        </keep-alive>
      </div>
    </v-card>
  </default>
</template>

<script>
import Default from '../../layouts/Default';
import CardTitle from '../../components/CardTitle';
import BackButton from '../../components/BackButton';
import WealthClientQuotation from '../../components/rfq/wealthbuilder/Quote';
import ProductMixin from '@/mixins/ProductMixin';
import savedQuoteMixin from '../../mixins/savedQuoteMixin';

export default {
  name: 'WealthBuilderPlan',
  metaInfo: {
    title: 'RFQ - Wealth Builder Plan',
  },
  components: {
    BackButton, CardTitle, Default, WealthClientQuotation,
  },
  mixins: [ProductMixin, savedQuoteMixin],
  data() {
    return {
      wealthbuilder: true,
      current: WealthClientQuotation,
      productData: { prodCode: 2020196, optionCode: 2020215 },
      benefits: [],
      productOptions: [],
      terms: [],
      quoteSummaryData: {},
      termLimit: {
        min: 5,
        max: 100,
      },
      Wealthfrequency: ['Annually', 'Single Premium'],
    };
  },
  mounted() {
    this.checkSavedQuote('wealth');
    this.fetchProductOptions();
    this.fetchBenefits();
    this.fetchTerms(this.scanObject(this.productOptions, '0.optionCode', 2021217));
  },
};
</script>
